<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox data-type="button-checkbox-select"
                  [attr.data-label]="node.folder.name"
                  [checked]="isSelected(node)"
                  [disabled]="isDisabledOrHasParentSelected(node)"
                  (change)="nodeSelectionToggle(node)">{{node.folder.name}}</mat-checkbox>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button data-type="button-tree-node-toggle" [attr.data-label]="node.folder.name"
            [attr.aria-label]="'Toggle ' + node.folder.name" matTreeNodeToggle (click)="$event.stopPropagation();$event.preventDefault()">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox data-type="button-checkbox-select"
                  [attr.data-label]="node.folder.name"
                  [checked]="isSelected(node)"
                  [disabled]="isDisabledOrHasParentSelected(node)"
                  (change)="nodeSelectionToggle(node)">{{node.folder.name}}</mat-checkbox>
    <mat-progress-bar *ngIf="node.isLoading"
                      mode="indeterminate"
                      class="example-tree-progress-bar"></mat-progress-bar>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <button mat-raised-button
            color="primary"
            data-type="button-tree-node-toggle"
            [attr.data-label]="node.folder.name"
            (click)="loadMore(node);$event.stopPropagation();$event.preventDefault()" >
      <div class="load-more-button">
        <mat-progress-spinner *ngIf="node.isLoading"
                              [diameter]="16" mode="indeterminate"></mat-progress-spinner>
        {{node.folder.name}}

      </div>
    </button>
  </mat-tree-node>
</mat-tree>
<div class="loader-container" *ngIf="!loaded">
  <mat-progress-spinner diameter="36" mode="indeterminate"></mat-progress-spinner>
</div>
