import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-report-email-id-column',
  templateUrl: './email-id-column.component.html',
  styleUrls: ['./email-id-column.component.scss'],
})
export class EmailIdColumnComponent {

  @Input() emailId: string;
}
