import { ChangeDetectorRef, Component, } from '@angular/core';
import { Observable} from 'rxjs';
import {DialogService} from '../../../../../services/dialog.service';
import {ReportService} from '../../../../../services/report.service';
import {MessageExportJob, ResultPage} from '../../../../../models/rules-export-job';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../../../services/message.service';
import {ReportDetailExportComponent} from '../../common/detail-export.component';
import {tap} from 'rxjs/operators';
import {AuthService} from '../../../../../services/auth.service';

@Component({
  selector: 'app-rules-export-messages',
  templateUrl: './report-export-messages.component.html',
  styleUrls: ['./report-export-messages.component.scss']
})
export class ReportExportMessagesComponent extends ReportDetailExportComponent {

  public rulesExportJobId: string;
  public ruleName: string;
  public timestamp: number;

  private static DEFAULT_RULE_ID_FILTER = 'ALL';
  public currentRuleIdFilter = ReportExportMessagesComponent.DEFAULT_RULE_ID_FILTER;
  public ruleFilterValues: Map<string, string> =  new Map([['ALL', 'ALL']]);

  public columns = [
    {name: 'actions', show: true},
    {name: 'rule', show: true},
    {name: 'label', show: true},
    {name: 'subject', show: true},
    {name: 'messageId', show: true},
    {name: 'status', show: true},
    {name: 'exportMessage', show: true}];

  public isGoogle = this.authService.isGoogleProvider();

  constructor(private activatedRoute: ActivatedRoute,
              private reportService: ReportService,
              private authService: AuthService,
              private _messageService: MessageService,
              protected router: Router,
              private _dialogService: DialogService,
              private _cdr: ChangeDetectorRef) {
    super(_messageService, _dialogService, _cdr);

    this.rulesExportJobId = this.activatedRoute.snapshot.params.rulesExportJobId;
    this.timestamp = this.activatedRoute.snapshot.params.timestamp;
  }

  reportMessagesDetailsExportJobs(limit: number, nextPageToken?: string): Observable<ResultPage<MessageExportJob>> {
    return this.reportService
        .reportMessagesExportJobs(this.rulesExportJobId, nextPageToken, limit, this.currentStatus, this.currentRuleIdFilter)
        .pipe(tap(result => this.createFilterByRule(result.rules)));
  }

  gotoRootPath(): void {
    const to = 'dashboard/report/rule';
    this.router.navigate([to]);
  }

  private createFilterByRule(rulesMap: Map<string, string>): void {
    this.ruleFilterValues = new Map([
      ...new Map([[ReportExportMessagesComponent.DEFAULT_RULE_ID_FILTER, ReportExportMessagesComponent.DEFAULT_RULE_ID_FILTER]]),
      ...Object.entries(rulesMap)]
    );
    this._cdr.detectChanges();
  }

  filterByRule(ruleId: string): void {
    this.currentRuleIdFilter = ruleId;
    this.refreshList();
  }
}
