<div class="details-container" (click)="openDetails(messageExportJob)"  [ngClass]="{'in-error': inError}">
  <span data-type="job-details">{{message}} <mat-icon *ngIf="isTruncated" svgIcon="ao_open_in_new"></mat-icon></span>
  <ng-container *ngIf="messageExportJob.retriesDates">
    <!-- If the job has been retried and it's for the rule details show the current job date as the last retried date -->
    <ng-container *ngIf="isRuleExportDetail">
      <ng-container *ngTemplateOutlet="showRetryDate; context: {timestamp: messageExportJob.timestamp, message: 'Retried on'}"></ng-container>
    </ng-container>
    <!-- Show all retried dates -->
    <ng-container *ngFor="let retryDate of messageExportJob.retriesDates; index as i; last as last">
      <!-- If it's for rule details don't show the last retried date because it's the same as the rule export  -->
      <ng-container *ngIf="isRuleExportDetail && !last">
        <ng-container *ngTemplateOutlet="showRetryDate; context: {timestamp: retryDate, message: 'Retried on'}"></ng-container>
      </ng-container>
      <!-- Otherwise show the date  -->
      <ng-container *ngIf="!isRuleExportDetail">
        <ng-container *ngTemplateOutlet="showRetryDate; context: {timestamp: retryDate, message: 'Previously tried'}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #showRetryDate let-timestamp="timestamp" let-message="message">
  <div class="previously-tried" data-type="job-details">
    {{message}} {{timestamp | date:'medium'}}.
  </div>
</ng-template>
