<section class="main-content aoRow">
  <mat-card>
    <mat-card-content>
      <img alt="logo" class="logo-size" src="assets/img/logo_color.svg">

      <br>
      <p>What is this AODocs addon</p>
      This Gmail addon let you file your emails into an AODocs library.
      <br>
      <br>

      <p>Why we need your permission</p>
      After you have installed the addon you need to authorize it in order to let the addon save your email into AODocs
      <br>
      <br>
      More precisely we ask for:
      <br>

      <ul>
        <li><i>View and manage Google Drive files and folders opened or created by this app:</i> to be able to store files in AODocs</li>
        <li><i>View and modify but not delete the current email:</i> to retrieve data from current email and apply a label when the export process is completed.</li>
      </ul>
      <br>
      <p>Disclosure</p>
      <p class="disclosure">
        <i> AODocs Gmail Addon's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</i>
      </p>

      <p>Links</p>
      <a href="https://www.aodocs.com/terms-of-service" target="_blank">Terms of Service</a>
      <br>
      <a href="https://www.aodocs.com/privacy-policy" target="_blank">Privacy Policy</a>
      <br>
    </mat-card-content>
  </mat-card>
</section>

