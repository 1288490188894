import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DialogService} from '../../../../services/dialog.service';
import {ReportService} from '../../../../services/report.service';
import {RulesExportJob} from '../../../../models/rules-export-job';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rules-export',
  templateUrl: './report-export-rules.component.html',
  styleUrls: ['./report-export-rules.component.scss']
})
export class ReportExportRulesComponent implements OnInit, AfterViewInit, OnDestroy {

  public searchValueSubject: Subject<string> = new Subject<string>();
  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private nextPageToken: string;

  public dataSource = new MatTableDataSource<RulesExportJob>();
  public loading$ = this.loadingSubject.asObservable();
  public pageSize = 30;
  public pageIndex = 0;
  public maxPageIndex = 0;
  public totalItems = 0;
  public currentStatus = 'ALL';

  private paginatorGetRangeLabelTmp: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columns = [
    {name: 'view', show: true},
    {name: 'id', show: false},
    {name: 'timestamp', show: true},
    {name: 'ruleIds', show: true},
    {name: 'exported', show: true},
    {name: 'errors', show: true},
    {name: 'status', show: true},
    {name: 'message', show: true}];

  public filterValues: Map<string, string> = new Map([
    ["ALL", "All"],
    ["FINISHED", "Success"],
    ["RUNNING", "In progress"],
    ["FAILED", "Errors"]
  ]);

  constructor(private reportService: ReportService,
              private ngZone: NgZone,
              protected router: Router,
              private dialogService: DialogService,
              private sanitizer: DomSanitizer,
              private iconRegistry: MatIconRegistry) {

    iconRegistry.addSvgIcon('view_data', sanitizer.bypassSecurityTrustResourceUrl('assets/img/view_data.svg'));
  }

  ngOnInit(): void {
    this.loadReportJobs();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.searchValueSubject.unsubscribe();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refresh(): void {
    this.dataSource.data = [];
    this.pageIndex = 0;
    this.maxPageIndex = 0;
    this.totalItems = 0;
    this.loadReportJobs();
  }

  loadReportJobs(nextPageToken?: string, limit: number = this.pageSize): void {
    this.loadingSubject.next(true);
    this.subscriptions.push(
      this.reportService.reportRulesExportJobs(nextPageToken, limit, this.currentStatus).subscribe((resp) => {
        this.loadingSubject.next(false);

        this.nextPageToken = resp.nextPageToken;

        const newData = (resp.entities || [])
          .map(item => ({
              ...item,
              userStatus: this.getStatusForUsers(item.status)
            }
          ));

        this.updateDataSource(newData, resp.count);

        this.refreshPaginator();

      }, (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error.error.error.message);
      })
    );
  }

  private updateDataSource(data: RulesExportJob[], totalCount: number): void {
    if (this.dataSource.data.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(data);
    } else {
      this.dataSource.data = data;
    }

    // this is a hack to show the next button on the pagination if it is not possible to display the total number of lines in the pagination,
    // because the count we have is for batches and not individual rules. So we’ll display “1-30 of more than NUMBER_OF_BATCHES”, since there is at least one rule per batch
    this.totalItems = this.dataSource.data.length < totalCount ?
      totalCount :
      // if there is more data than the total count (more than 1 rule in some RulesExport), we add 1 to the total number of items to display the next button (if next page token is not null because it is not the last page)
      this.dataSource.data.length + (this.nextPageToken ? 1 : 0);
  }

  private refreshPaginator(): void {
    setTimeout(() => {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.length = this.totalItems;
    });
  }

  handlePageEvent(e: PageEvent): void {
    const isNewPageNotAlreadyLoaded = e.pageIndex > this.maxPageIndex;
    if (isNewPageNotAlreadyLoaded) {
      this.loadReportJobs(this.nextPageToken, this.pageSize);
    }
    this.maxPageIndex = isNewPageNotAlreadyLoaded ? e.pageIndex : this.maxPageIndex;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  filterByStatus(status: string): void {
    this.currentStatus = status;
    this.refresh();
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name);
  }

  displayLabelList(rulesExportJob: RulesExportJob): string {
    return rulesExportJob.ruleNames.map(a => a).toString();
  }

  viewDetails(rulesExportJob: RulesExportJob): void {
    const to = 'dashboard/report/rule/' + rulesExportJob.id + '/' + rulesExportJob.timestamp
    this.router.navigate([to]);
  }

  showDetailsMessage(rulesExportJob: RulesExportJob): string {
    if (rulesExportJob.message && rulesExportJob.status === 'FINISHED') {
      return '';
    }
    return rulesExportJob.message;
  }

  getStatusForUsers(status: string): string {
    switch (status) {
      case 'RUNNING': {
        return 'IN PROGRESS';
      }
      case 'FINISHED': {
        return 'SUCCESS';
      }
      case 'FAILED':
      case 'ERROR': {
        return 'ERRORS';
      }
      default: {
        return status;
      }
    }
  }

  getJobStatusClass(status: string): string {

    switch (status) {
      case 'FINISHED': {
        return 'label-success';
      }
      case 'RUNNING': {
        return 'label-default';
      }
      case 'RETRY':
      case 'FAILED':
      case 'ERROR': {
        return 'label-warning';
      }
    }
  }
}
