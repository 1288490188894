import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';
import {AuthContext} from '../models/AuthContext.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {filter, tap} from 'rxjs/operators';
import {Scope} from '../constants/scopes';
import {AuthProvider} from '../constants/auth-provider-types';
import {
  AuthStorage,
  isGoogleAuthStorage,
  isGoogleFirebaseAuthStorage,
  isMicrosoftAuthStorage, isValidAuthStorageType,
} from '../models/auth-storage.model';
import {LocalStorageUtils} from './local-storage-utils';
import 'firebase/auth';
import {FirebaseApp, getApp, initializeApp} from 'firebase/app';
import {Auth, getAuth, onAuthStateChanged, updateEmail} from 'firebase/auth';
import {User} from '@firebase/auth';

export interface UserProfile {
  email: string;
  name: string;
  picture?: string;
}

export interface UserEmailUpdate {
  oldEmail: string;
  newEmail: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  profile: UserProfile;
  firebaseApp: FirebaseApp;
  loggedOut = false;
  refreshToken: string;
  authProvider: AuthProvider;
  domain: string;
  firebaseAuthentDone = false;
  initiatedAuth = new BehaviorSubject(false);
  profileSubject: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(undefined);
  needAuthentication = new BehaviorSubject(false);
  // Signal to know when the user email has been updated in Firebase
  needUserEmailUpdateSubject = new BehaviorSubject<UserEmailUpdate>(null);
  // Signal to know when the user email has been updated in the backend
  userEmailUpdateFinished: BehaviorSubject<string> = new BehaviorSubject(null);
  redirectUrl: UrlTree | string = '/';
  firebaseLastRedirectUrl: UrlTree;
  userEmailUpdateInProgressValue: string;
  private readonly auth: Auth;
  // tslint:disable-next-line:variable-name
  private _pendingRedirect$ = new BehaviorSubject(true);
  private readonly defaultPictureUrl = 'assets/img/google-default-profile-picture.jpg';

  constructor(private authCtx: AuthContext,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private ngZone: NgZone,
              private httpClient: HttpClient) {

    const firebaseConfig = {
      apiKey: environment.firebaseApiKey,
      authDomain: environment.firebaseAuthDomain,
    };
    let storedInfo = LocalStorageUtils.getAuthStorage();
    // Remove legacy google authentication
    if (!!storedInfo && isGoogleAuthStorage(storedInfo)) {
      LocalStorageUtils.removeAuthStorage();
    }
    try {
      this.firebaseApp = getApp();
    } catch {
      this.firebaseApp = initializeApp(firebaseConfig);
    }

    this.initUpdateEmailSignal();

    this.auth = getAuth(this.firebaseApp);

    this.authenticationInProgress = true;

    onAuthStateChanged(this.auth, (user) => {
      gapi.load(this.authCtx.apis, () => {
        const isAuthContextValid = this.validateEmailProviderContext();
        if (!isAuthContextValid) {
          return;
        }

        this.initFirebaseEmailUpdate(user);

        if (!this.authenticationInProgress) {
          return;
        }

        // Check if the user email has to be updated in Firebase
        const firebaseEmailUpdateInProgress = !!this.userEmailUpdateInProgressValue;

        if (user) {
          const userEmail = this.syncFirebaseUserInfo(user);

          // If the update is in progress, do not store the user info in local storage, just return
          if (firebaseEmailUpdateInProgress) {
            return;
          }

          user.getIdToken().then(token => {
            const providers = user.providerData;
            let isMicrosoft = providers[0].providerId === 'microsoft.com';
            // If multi providers, check if the user is flag as a Microsoft user in the storage
            if (providers.length > 1) {
              isMicrosoft = !!storedInfo?.type ? storedInfo.type === AuthProvider.MICROSOFT : isMicrosoft;
            }
            const authProvider = isMicrosoft ? AuthProvider.MICROSOFT : AuthProvider.GOOGLE_FIREBASE;
            const domainInStorage = storedInfo?.domain;

            storedInfo = {
              type: authProvider,
              email: userEmail,
              name: user.displayName,
              picture: isMicrosoft ? this.defaultPictureUrl : user.photoURL,
              accessToken: token
            };
            storedInfo = {...storedInfo, refreshToken: user.refreshToken};
            storedInfo = this.forceDomainIfPresent(storedInfo, domainInStorage);
            LocalStorageUtils.setAuthStorage(storedInfo);

            this.setAuthType(authProvider);
            this.setToken(token);
            this.refreshToken = (isMicrosoftAuthStorage(storedInfo) || isGoogleFirebaseAuthStorage(storedInfo))
              ? storedInfo.refreshToken : undefined;

            this.setFirebaseProfile();
            this.firebaseAuthentDone = true;
            this.needAuthentication.next(false);
          });
        } else {
          this.authenticationInProgress = !!firebaseEmailUpdateInProgress;
          this.setProfile(null);
          this.setAuthType(null);
          this.needAuthentication.next(true);
        }

        this.ngZone.run(() => {
          this.initiatedAuth.next(true);
        });
      });
    });
  }

  get authenticationInProgress(): boolean {
    return this._pendingRedirect$.value;
  }

  set authenticationInProgress(value: boolean) {
    this._pendingRedirect$.next(value);
  }

  static getRawScopes(isGmail: boolean = true): string[] {
    const scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID];
    if (isGmail) {
      scopes.push(Scope.GMAIL);
    }
    return scopes;
  }

  static getScopes(isGmail: boolean = true): string {
    return AuthService.getRawScopes(isGmail).join(' ');
  }

  isGoogleProvider(): boolean {
    return this.authProvider === AuthProvider.GOOGLE || this.authProvider === AuthProvider.GOOGLE_FIREBASE;
  }

  isMicrosoftProvider(): boolean {
    return this.authProvider === AuthProvider.MICROSOFT;
  }

  logout(): void {
    this.clearAuthForLogout();
    getAuth().signOut().then(() => {
      this.router.navigate(['login']);
    });
  }

  setToken(token: string): void {
    gapi.client.setToken({access_token: token});
  }

  setProfile(profile: UserProfile): void {
    this.profile = profile;
    this.ngZone.run(() => {
      this.profileSubject.next(profile);
    });
  }

  setDomain(domain: string): void {
    this.domain = domain;
  }

  setAuthType(authType: AuthProvider): void {
    this.authProvider = authType;
  }

  refreshFirebaseToken(provider: AuthProvider): Observable<any> {
    return this.httpClient
      .post<{ access_token: string; refresh_token: string }>(
        `${environment.firebaseRefreshUrl}?key=${
          environment.firebaseApiKey
        }`,
        {
          grant_type: 'refresh_token',
          refresh_token: this.refreshToken,
        }
      )
      .pipe(
        tap(({access_token, refresh_token}) => {
          this.refreshToken = refresh_token;
          this.setToken(access_token);
          LocalStorageUtils.setAuthStorage({
            type: provider,
            email: this.profile.email,
            name: this.profile.name,
            picture: this.profile.picture,
            accessToken: access_token,
            refreshToken: refresh_token,
            domain: this.domain
          });
        })
      );
  }

  private initFirebaseEmailUpdate(firebaseUser?: User): void {
    const originUser = this.getOriginUserFromURL();
    if (!this.authenticationInProgress && !!firebaseUser && !!originUser && originUser !== firebaseUser.email) {
      this.userEmailUpdateInProgressValue = originUser;
      this.authenticationInProgress = true;
    }
  }

  private getOriginUserFromURL(): string {
    const urlParams = new URLSearchParams(location.search);
    const userQueryParam = this.activatedRoute.snapshot.queryParams.user;
    return !!userQueryParam ? userQueryParam : urlParams.get('user');
  }

  private setFirebaseProfile(): void {
    const storedInfo = LocalStorageUtils.getAuthStorage();
    this.setProfile({
      name: storedInfo.name,
      email: storedInfo.email,
      picture: storedInfo.picture
    });
  }

  /**
   * Wait for the AuthService to indicate that the user email has been updated in the backend. Then reload the page.
   */
  private initUpdateEmailSignal(): void {
    this.userEmailUpdateFinished.pipe(filter(newEmail => !!newEmail)).subscribe((newEmail) => {
      console.log('User email update to ' + newEmail + ' in backend finished, so refresh');
      this.logoutFromFirebase(this.firebaseLastRedirectUrl);
    });
  }

  /**
   * If the user has a different email in Firebase than in the provider, update it in Firebase.
   * This is because the email was renamed and firebase is not sync.
   * @param user, the user info.
   */
  private syncFirebaseUserInfo(firebaseUser?: User): string {
    // User sent by the Addon/Addin (it's the trusted user email used to start the process)
    const originUser = this.getOriginUserFromURL();
    // Email as a signal to know that the user email has to be updated in identity plateform
    const newUserEmailValue = this.userEmailUpdateInProgressValue;

    const firebaseEmail = firebaseUser?.email;
    const firebaseEmailInProvider = firebaseUser?.providerData[0]?.email;

    if (!!newUserEmailValue && newUserEmailValue !== firebaseEmail) {
      this.updateUserEmailInFirebase(firebaseUser, newUserEmailValue);
      return newUserEmailValue;
    }
    else if (!!originUser && firebaseEmailInProvider !== originUser) { // the email from the Addon is different from the one in Firebase
      // Logout and reload the page with the signal to update the email in firebase
      this.logoutFromFirebase(this.getRedirectUrl(), originUser);
      return firebaseEmailInProvider;
    }
    else if (!!firebaseEmail && firebaseEmail !== firebaseEmailInProvider) {
      // Logout and reload the page with the signal to update the email in firebase
      this.logoutFromFirebase(this.getRedirectUrl(), firebaseEmailInProvider);
      return firebaseUser.email;
    }
    return firebaseEmailInProvider;
  }

  /**
   * Update the email in Firebase and logout to force the user to login again to have an updated JWT.
   * @param firebaseUser, the firebase user
   * @param newUserEmailValue, the new email to set in Firebase
   */
  private updateUserEmailInFirebase(firebaseUser: User, newUserEmailValue: string): void {
    const currentUrl = this.getRedirectUrl();
    const oldEmail = firebaseUser.email;
    updateEmail(firebaseUser, newUserEmailValue).then(() => {
      console.log('User updated in Firebase: from ' + oldEmail + ' to ' + getAuth(this.firebaseApp).currentUser.providerData[0].email);
      this.userEmailUpdateInProgressValue = undefined;
      // Send a signal to the AuthService to indicate that the user email has been updated in Firebase
      this.needUserEmailUpdateSubject.next({
        oldEmail,
        newEmail: newUserEmailValue
      });
    }).catch((error) => {
      console.error('Error updating email so logout to force the user to login:', error);
      this.logoutFromFirebase(currentUrl);
    });
  }

  private getRedirectUrl(): UrlTree {
    // To force to redirect with current URL if the router URL is the root
    return this.router.parseUrl(location.pathname + location.search);
  }

  /**
   * Logout and set the new user email in the storage then reload the page to the redirect URL.
   * The user email is set in the storage to be able to update the email in Firebase after the logout.
   * @param signOutRedirectUrl, the URL to redirect to after logout.
   * @param user, the user email to update in Firebase.
   */
  private logoutFromFirebase(signOutRedirectUrl: UrlTree, userEmail?: string): void {
    this.clearAuthForLogout();
    // Sing-out and reload the page with the signal to update the email in firebase
    this.userEmailUpdateInProgressValue = userEmail;
    getAuth().signOut().then(() => {
      this.redirectUrl = signOutRedirectUrl;
      console.log('Logout from Firebase and reload the page to update the email in Firebase:' + signOutRedirectUrl);
      this.router.navigateByUrl(signOutRedirectUrl);
    });
  }

  private clearAuthForLogout(): void {
    LocalStorageUtils.removeAuthStorage();
    LocalStorageUtils.removeAuthDomain();
    localStorage.removeItem('aodocs-authentication');
    this.setToken(null);
    this.setAuthType(null);
    this.setProfile(null);
    this.loggedOut = true;
  }

  /**
   * The domain in the URL should be the one to use. Otherwise, the domain given by the authentication form.
   */
  private forceDomainIfPresent(storedInfo: AuthStorage, domainInStorage?: string): AuthStorage {
    const domainToUse = this.getQueryStringValue('domain');
    // If the domain is different from the one in the storage, clear the last library and document type
    if (domainToUse && domainInStorage !== domainToUse) {
      LocalStorageUtils.removeLastLibrary();
      LocalStorageUtils.removeLastDocumentType();
    }

    if (!!domainToUse) {
      storedInfo = {...storedInfo, domain: domainToUse};
      LocalStorageUtils.setAuthStorage(storedInfo);
    }
    return storedInfo;
  }

  /**
   * Validate the email provider context with the local storage session.
   * If the auth storage type is not compliant with the auth mode asked, clear the auth storage.
   */
  private validateEmailProviderContext(): boolean {
    const storedInfo = LocalStorageUtils.getAuthStorage();
    const authMode = this.getQueryStringValue('origin');

    if (!!storedInfo && !isValidAuthStorageType(storedInfo, authMode)) {
      console.log('The auth storage type "' + storedInfo.type + '" is not compliant with the authMode "' + authMode + '" asked. Logout...');
      this.clearAuthForLogout();
      const currentRedirectUrl = this.getRedirectUrl();
      getAuth().signOut().then(() => {
        this.redirectUrl = currentRedirectUrl;
        this.router.navigateByUrl(currentRedirectUrl);
      });
      return false;
    }
    return true;
  }

  private getQueryStringValue(key: string): string {
    return new URL(window.location.href).searchParams.get(key);
  }
}
