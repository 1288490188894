<header>
  <section class="aoRow aoRow--left title-container">
    <img alt="logo" class="logo" src="assets/img/logo_white.svg">
    <ng-container *ngIf="isGoogle">
      <mat-divider class="horizontal-divider" vertical></mat-divider>
      <h2 data-type="header-title">Gmail add-on dashboard</h2>
    </ng-container>

    <ng-container *ngIf="isMicrosoft">
      <mat-divider class="horizontal-divider" vertical></mat-divider>
      <h2 data-type="header-title">Outlook add-in dashboard</h2>
    </ng-container>
  </section>
  <section class="details-container">
    <app-domain-viewer></app-domain-viewer>
    <app-help></app-help>
    <app-profile></app-profile>
  </section>
</header>
