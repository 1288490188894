import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DialogService} from '../../../../services/dialog.service';
import {MessageExportJob, MessageStatus, ResultPage} from '../../../../models/rules-export-job';
import {MessageService} from '../../../../services/message.service';

/**
 * Abstract component to display the list of export jobs.
 */
@Component({
  template: ''
})
export abstract class ReportDetailExportComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {
  }
  private static DEFAULT_STATUS = 'ALL';

  private loadingSubject = new BehaviorSubject<boolean>(true);
  private subscriptions: Subscription[] = [];
  private nextPageToken: string;

  public dataSource = new MatTableDataSource<MessageExportJob>();
  public loading$ = this.loadingSubject.asObservable();
  public displayedColumns = [];
  public pageSize = 30;
  public pageIndex = 0;
  public maxPageIndex = 0;
  public totalItems = 0;
  public currentStatus = ReportDetailExportComponent.DEFAULT_STATUS;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columns = [];

  public readonly statusFilterValues: Map<string, string> = new Map([
    ['ALL', 'All'],
    ['FINISHED', 'Success'],
    ['ALREADY_EXPORTED', 'Already saved'],
    ['IN_PROGRESS', 'In progress'],
    ['WARNING', 'Warning'],
    ['FAILED', 'Failed']
  ]);

  abstract reportMessagesDetailsExportJobs(limit: number, nextPageToken?: string): Observable<ResultPage<MessageExportJob>>;

  ngOnInit(): void {
    this.loadReportJobs(undefined, this.pageSize);
    this.displayedColumns = this.columns.filter(column => column.show).map(column => column.name);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  refreshList(): void {
    this.dataSource.data = [];
    this.pageIndex = 0;
    this.maxPageIndex = 0;
    this.totalItems = 0;
    this.loadReportJobs();
  }

  loadReportJobs(nextPageToken?: string, limit: number = this.pageSize): void {
    this.loadingSubject.next(true);
    this.subscriptions.push(
      this.reportMessagesDetailsExportJobs(limit, nextPageToken)
        .subscribe((resp) => {
          this.loadingSubject.next(false);

          this.nextPageToken = resp.nextPageToken;
          this.totalItems = resp.count;

          const newData = (resp.entities || [])
            .map(item => ({
                ...item,
                userStatus: this.getStatusForUsers(item.status),
                statusClass: this.getStatusClass(item.status),
                isCalculatedTitle: this.isCalculatedTitle(item.messageSubject)
              }
            ));

          this.updateDataSource(newData);

          this.refreshPaginator();

        }, (error) => {
          this.loadingSubject.next(false);
          this.dialogService.error(error.error?.message ?? error.message);
        }),
    );
  }

  private updateDataSource(data: MessageExportJob[]): void {
    if (this.dataSource.data.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(data);
    } else {
      this.dataSource.data = data;
    }
  }

  private refreshPaginator(): void {
    setTimeout(() => {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.length = this.totalItems;
    });
  }

  handlePageEvent(e: PageEvent): void {
    const isNewPageNotAlreadyLoaded = e.pageIndex > this.maxPageIndex;
    if (isNewPageNotAlreadyLoaded) {
      this.loadReportJobs(this.nextPageToken, this.pageSize);
    }
    this.maxPageIndex = isNewPageNotAlreadyLoaded ? e.pageIndex : this.maxPageIndex;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  filterByStatus(status: string): void {
    this.currentStatus = status;
    this.refreshList();
  }

  /**
   * Retry a Job by its ID and refresh the page on ALL status to see the updated Job.
   * @param jobId
   */
  retry(jobId: string): void {
    this.loadingSubject.next(true);

    this.subscriptions.push(
      this.messageService
        .retry(jobId)
        .subscribe((resp) => {
          this.currentStatus = ReportDetailExportComponent.DEFAULT_STATUS;
          this.refreshList();
        }, (error) => {
          this.loadingSubject.next(false);
          this.dialogService.error(error.error?.message ?? error.message);
        }),
    );
  }

  isCalculatedTitle(messageSubject: string): boolean {
    return '{AODOCS_CALCULATED_TITLE}' === messageSubject;
  }

  getStatusForUsers(status: string): string {
    switch (status) {
      case 'FINISHED': {
        return 'SUCCESS';
      }
      case 'ALREADY_EXPORTED': {
        return 'ALREADY SAVED';
      }
      case 'IN_PROGRESS': {
        return 'IN PROGRESS';
      }
      case 'ENQUEUED': {
        return 'QUEUED';
      }
      default: {
        return status;
      }
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'FINISHED': {
        return 'label-success';
      }
      case 'ALREADY_EXPORTED': {
        return 'label-grey';
      }
      case 'WARNING': {
        return 'label-warning';
      }
      case 'FAILED': {
        return 'label-error';
      }
      default: {
        return 'label-default';
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
