import {SimpleLabel} from './simple-label.model';
import {SimpleLibrary} from './simple-library.model';
import {SimpleCategory, SimpleCategoryValue} from './simple-category.model';
import {SimpleDocumentClass} from './simple-document-class.model';
import {Folder} from './folder.model';
import {EmailProvider} from '../constants/auth-provider-types';

export interface Rule {
  id?: string;
  name: string;
  description?: string;
  labels: SimpleLabel[] | Folder[];
  library: SimpleLibrary;
  documentType: SimpleDocumentClass;
  category?: SimpleCategory;
  categoryValue?: SimpleCategoryValue;
  user: string;
  emailProvider: EmailProvider;
  includeSubFolder: boolean;
  automation: Automation;
}

export interface Automation {
  frequency: Frequency;
}

export enum Frequency {
  ONCE_PER_WEEK = 'ONCE_PER_WEEK'
}
