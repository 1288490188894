import {CommonFeatureGuard} from './common-feature-guard.service';
import {ActivatedRouteSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {inject} from '@angular/core';
import {AuthService} from '../auth.service';
import {filter, first, switchMap} from 'rxjs/operators';

/**
 * Guard for checking if the send and file feature is available.
 * Redirects to personal settings if the feature is not available or if it's a microsoft user.
 */
export const SendAndFileFeatureGuardCanActivate = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const commonFeatureGuard = inject(CommonFeatureGuard);

  return authService.initiatedAuth.pipe(
    filter(initiated => initiated),
    first(),
    switchMap(() => authService.profileSubject),
    filter(profile => profile !== undefined),
    switchMap(() => {
      if (authService.isMicrosoftProvider()) {
        return of(commonFeatureGuard.redirectToPersonalSettings());
      }
      return commonFeatureGuard.canActivate((features) => features.isSendAndFileAvailable);
    }));
};
