export enum NotificationTypes {
  ALWAYS = 'ALWAYS',
  IN_CASE_OF_ERROR = 'IN_CASE_OF_ERROR',
  NEVER = 'NEVER'
}

export interface NotificationSettings {
  bulkNotificationType: NotificationTypes;
  singleExportNotificationType: NotificationTypes;
}
