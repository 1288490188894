export interface RulesExportJob {
  id?: string;
  timestamp?: number;
  user?: string;
  ruleNames?: string[];
  status?: string;
  message?: string;
  exported: number;
  failed: number;
  total: number;
  automatic?: boolean;
}

export interface ResultPage<T> {
  entities: T[];
  count: number;
  nextPageToken: string;
  rules: Map<string, string>;
}

export interface MessageExportJob {
  jobId: string;
  messageId: string;
  messageSubject: string;
  exportMessage: string;
  labelName: string;
  ruleName: string;
  status: MessageStatus;
  timestamp: number;
  documentLink: string;
  retryable: boolean;
  retriesDates: number[];
}

export enum MessageStatus {
  ALL = 'ALL',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  ALREADY_EXPORTED = 'ALREADY_EXPORTED',
  FINISHED = 'FINISHED',
}
