<section id="csv-rules-import-statistics">
  <h2 mat-dialog-title data-type="text-dialog-title">Result of the CSV Import</h2>

  <button data-type="button-dialog-close" mat-mini-fab color="primary" class="close-button" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>

  <div mat-dialog-content>
    <div>
      <table mat-table [dataSource]="dataSource">
        <!-- id -->
        <ng-container matColumnDef="ruleName">
          <mat-header-cell *matHeaderCellDef><span>Rule name</span></mat-header-cell>
          <mat-cell *matCellDef="let element" data-type="text-rule-name"><span> {{ element.ruleName }} </span>
          </mat-cell>
        </ng-container>

        <!-- description -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef><span>Status</span></mat-header-cell>
          <mat-cell *matCellDef="let element" data-type="text-rule-status">
            <mat-icon *ngIf="element.error" class="red">error</mat-icon>
            <mat-icon *ngIf="!element.error" class="green">check_circle</mat-icon>
          </mat-cell>
        </ng-container>

        <!-- library -->
        <ng-container matColumnDef="error">
          <mat-header-cell *matHeaderCellDef>Details</mat-header-cell>
          <mat-cell *matCellDef="let element" data-type="text-rule-error">
            <span class="red" *ngIf="element.error">  {{ element.error }}</span>
            <span *ngIf="!element.error"> - </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();" data-type="row-rule" [attr.data-label]="row.ruleName"></mat-row>
      </table>
    </div>
  </div>
</section>
