<section class="main-content">
  <form [formGroup]="form">
    <section class="aoRow aoRow--btw aoRow--start">
      <h1>Notifications</h1>
      <section mat-dialog-actions align="end">
        <section class="aoRow aoRow--right">
          <button data-type="button-personal-settings-reset"
                  mat-raised-button
                  color="accent"
                  [disabled]="formDisabled()"
                  (click)="delete()"> Reset </button>
          <button data-type="button-personal-settings-save"
                  mat-raised-button
                  color="primary"
                  [disabled]="formDisabled()"
                  (click)="save()"> Save </button>
        </section>
      </section>
    </section>
    <mat-card class="settings-card">
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <section class="settings-form-container">
        <section *ngIf="!(loading$ | async)">
          <section class="notification-container">
            <section class="single-notification">
              <mat-label>Receive reports by email after single exports:</mat-label>
              <mat-select formControlName="singleExportNotificationType" data-type="single-export-notification-type">
                <mat-option *ngFor="let notificationType of notificationTypesOptions" [value]="notificationType.value" data-type="filter-option">{{ notificationType.label }}</mat-option>
              </mat-select>
            </section>
            <section class="bulk-notification">
              <mat-label>Receive reports by email after batch exports:</mat-label>
              <mat-select formControlName="bulkNotificationType" data-type="notification-type">
                <mat-option *ngFor="let notificationType of notificationTypesOptions" [value]="notificationType.value" data-type="filter-option">{{ notificationType.label }}</mat-option>
              </mat-select>
            </section>
          </section>

        </section>
      </section>
    </mat-card>
  </form>
</section>

