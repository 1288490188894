import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FolderResult} from '../models/folder.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FolderService extends AbstractService {
  static PAGE_SIZE = 15;

  constructor(private httpClient: HttpClient) {
    super();
  }

  public list(skip = 0,  parentId?: string): Observable<FolderResult> {
    const url = this.toUrl('exporter', 'outlook-folders', ApiVersion.V1);
    const params: { skip: string, top: string, parentId?: string } =
      { skip: String(skip), top: String(FolderService.PAGE_SIZE) };
    if (parentId) {
      params.parentId = parentId;
    }
    return this.httpClient.get<FolderResult>(url, { params }).pipe(
      map(result => ({
        ...result, folders: result.folders || []
      }))
    );
  }
}
