import {Folder} from '../../../../../models/folder.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {FolderService} from '../../../../../services/folder.service';
import {DialogService} from '../../../../../services/dialog.service';
import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {map} from 'rxjs/operators';

export const LOAD_MORE = 'LOAD MORE';

export class FolderTreeNode {

  public isLoading = false;
  public expanded = false;

  constructor(
    public folder: Folder,
    public level = 0
  ) {}

  static getLoadMoreNode(id: string, level: number): FolderTreeNode {
    return new FolderTreeNode(
      { id: `${LOAD_MORE}_${id}`, name: LOAD_MORE, childFolderCount: 0},
      level);
  }
  get hasChildren(): boolean {
    return !!this.folder.childFolderCount;
  }
}

@Injectable()
export class FolderDatabase {
  dataChange = new BehaviorSubject<FolderTreeNode[]>([]);
  nodeMap = new Map<string, FolderTreeNode>();
  childMap = new Map<string, string[]>();
  data: FolderTreeNode[] = [];
  rootFolders: string[] = [];
  totalCount = 0;
  rootCount = 0;

  constructor(private folderService: FolderService, private dialogService: DialogService) {}

  public initialize(): void {
    this.loadFolders();
  }

  public expandNode(parentId: string): void {
    const children = this.childMap.get(parentId) || [];

    if (children.length) {
      this.showChild(parentId);
      this.dataChange.next(this.data);
    } else {
      this.loadFolders(0, parentId);
    }
  }

  public loadMore(parentId?: string): void {
    const children = this.childMap.get(parentId) || [];
    const skip = parentId ? children.length : this.rootFolders.length;
    this.loadFolders(skip, parentId);
  }

  public remove(parentId: string): void {
    const index = this.data.findIndex(node => node.folder.id === parentId);
    const nbChildren = this.countChildren(parentId);
    const parent = this.nodeMap.get(parentId);
    parent.isLoading = false;
    this.data.splice(index, nbChildren + 1, parent);
    this.dataChange.next(this.data);
  }

  public getChildren(parentId: string): Folder[] {
    const children: string[] = this.childMap.get(parentId) || [];
    return children.reduce((acc, next) => [ ...acc, this.nodeMap.get(next).folder, ...this.getChildren(next)], []);
  }

  public getParents(childId: string): Folder[] {
    const child = this.nodeMap.get(childId);
    const parent: FolderTreeNode | undefined = this.nodeMap.get(child.folder.parentId);
    let parents = [];
    if (parent) {
      parents = [...this.getParents(parent.folder.id), parent.folder];
    }
    return parents;
  }

  private countChildren(parentId: string): number {
    const children = this.childMap.get(parentId);
    if (!children) {
      return 0;
    } else {
      const hasLoadMorePadding = this.hasLoadMoreNode(parentId) ? 1 : 0;
      return children.reduce((count, child) => {
        const childCount = this.nodeMap.get(child)?.expanded ? this.countChildren(child) : 0;
        return count + 1 + childCount;
      }, hasLoadMorePadding);
    }
  }

  private hasLoadMoreNode(parentId: string): boolean {
    const node = this.nodeMap.get(parentId);
    const children = this.childMap.get(parentId);
    return !!children?.length && node.folder.childFolderCount > children.length;
  }

  public showChild(parentId: string, skip = 0): void {
    const index = this.data.findIndex(node => node.folder.id === parentId);
    const parent = this.nodeMap.get(parentId);
    const children = this.childMap.get(parentId).map(nodeId => this.nodeMap.get(nodeId));
    parent.isLoading = false;

    const addedNodes = this.hasLoadMoreNode(parentId) ?
      [ parent, ...children, FolderTreeNode.getLoadMoreNode(parentId, parent.level + 1)] :
      [ parent, ...children ];
    const nbChildren = this.countChildren(parentId);
    const newChildren = this.childMap.get(parentId).length - skip;
    let deletedCount = skip ? 1 + nbChildren - newChildren : 1;
    if (!this.hasLoadMoreNode(parentId) && skip) {
      deletedCount++;
    }
    this.data.splice(index, deletedCount, ...addedNodes);
    children.filter(c => c.expanded).forEach(c => this.showChild(c.folder.id));
  }

  public hasMoreRootFolders(): boolean {
    return this.totalCount > this.rootCount;
  }

  private loadFolders(skip: number = 0, parentId?: string): void {
    this.folderService.list(skip, parentId).subscribe((result) => {
      if (parentId) {
        const parent = this.nodeMap.get(parentId);
        const existingChildren = this.childMap.get(parentId) || [];
        this.childMap.set(parentId, [ ...existingChildren, ...result.folders.map(f => f.id)]);
        result.folders.forEach(folder => {
          this.nodeMap.set(folder.id, new FolderTreeNode({ ...folder, parentId }, parent.level + 1));
        });
        this.showChild(parentId, skip);
      } else {
        this.data.pop();
        this.totalCount = result.folderCount;
        this.rootCount += result.folders.length;
        result.folders.forEach(folder => {
          const node = new FolderTreeNode(folder);
          this.nodeMap.set(folder.id, node);
          this.data.push(node);
        });
        this.rootFolders = [ ...this.rootFolders, ...result.folders.map(f => f.id)];
        if (this.hasMoreRootFolders()) {
          this.data.push(new FolderTreeNode({ id: LOAD_MORE, name: LOAD_MORE, childFolderCount: 0}, 1));
        }
      }
      this.dataChange.next(this.data);
    }, (error) => {
      this.dialogService.error(error.error.error.message);
    });
  }
}

export class FolderDataSource implements DataSource<FolderTreeNode> {
  subscription: Subscription;
  get data(): FolderTreeNode[] {
    return this.database.dataChange.value;
  }

  set data(value: FolderTreeNode[]) {
    this.treeControl.dataNodes = value;
  }

  constructor(
    private treeControl: FlatTreeControl<FolderTreeNode>,
    private database: FolderDatabase,
  ) {}

  public connect(collectionViewer: CollectionViewer): Observable<FolderTreeNode[]> {
    this.subscription = this.treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<FolderTreeNode>).added ||
        (change as SelectionChange<FolderTreeNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<FolderTreeNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.database.dataChange).pipe(map(() => this.data));
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

  public handleTreeControl(change: SelectionChange<FolderTreeNode>): void {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .forEach(node => this.toggleNode(node, false));
    }
  }

  public toggleNode(node: FolderTreeNode, expand: boolean): void {
    const index = this.data.indexOf(node);
    if (index < 0) {
      return;
    }

    node.isLoading = true;
    node.expanded = expand;

    if (node.expanded) {
      this.database.expandNode(node.folder.id);
    } else {
      this.database.remove(node.folder.id);
    }
  }
}
