import {AuthProvider} from '../constants/auth-provider-types';

export interface AuthStorage {
  type: AuthProvider;
  email: string;
  name: string;
  domain?: string;
  picture?: string;
  accessToken: string ;
  refreshToken?: string;
}

export enum AuthMode {
  OUTLOOK = 'outlook',
  GMAIL = 'gmail',
  BOTH = 'both'
}

export function isMicrosoftAuthStorage(storedInfo: AuthStorage): boolean {
  return storedInfo.type === AuthProvider.MICROSOFT;
}

export function isGoogleAuthStorage(storedInfo: AuthStorage): boolean {
  return storedInfo.type === AuthProvider.GOOGLE;
}
export function isGoogleFirebaseAuthStorage(storedInfo: AuthStorage): boolean {
  return storedInfo.type === AuthProvider.GOOGLE_FIREBASE;
}

/**
 * Check if the stored auth type is valid based on the auth mode asked.
 * @param storedInfo, the stored auth info
 * @param authMode, the auth mode asked
 */
export function isValidAuthStorageType(storedInfo: AuthStorage, authMode: string): boolean {
  return !authMode ||
    authMode === AuthMode.BOTH ||
    storedInfo.type === AuthProvider.GOOGLE_FIREBASE && authMode === AuthMode.GMAIL ||
    storedInfo.type === AuthProvider.MICROSOFT && authMode === AuthMode.OUTLOOK;
}

