<ng-container *ngIf="(domain$ |async) as domain">
  <section class="aoRow aoHide-md">
    <mat-icon class="domain-header-logo">business</mat-icon>
    <div class="domain-header" data-type="domain">{{domain}}</div>
  </section>
  <section class="aoShow-md">
    <mat-icon class="domain-header-logo" data-type="button-domain-menu" [matMenuTriggerFor]="appMenu">business</mat-icon>
    <mat-menu #appMenu>
      <p class="domain-header-xs" data-type="domain">{{domain}}</p>
    </mat-menu>
  </section>
</ng-container>

