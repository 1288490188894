<app-header></app-header>
<section class="login-section">
  <img alt="logo" class="logo-size" src="assets/img/icon-128.png">
  <h1> Sign in to the AODocs email add-on</h1>
  <h2 *ngIf="emailAddressChanged" class="new-email-address-warning">
    <mat-icon>warning</mat-icon>
    Your email address has changed recently. You will be asked to sign in 2 times to sync your authentication.
  </h2>
  <div *ngIf="authMode !== 'outlook'" class="mat-elevation-z2 sign-in-button">
    <ao-google-firebase-login
            (loginError)="loginError($event)"
            (loginSuccess)="googleLoginSuccess($event)"
            [disabled]="isLoading"
            [firebaseApiKey]="firebaseApiKey"
            [firebaseAuthDomain]="firebaseAuthDomain"
            [scopes]="scopes"
            [tenantId]="null"
            [loginHint]="userEmail"
            display-mode="content"
            id="gfLogin"
    >
      <div (click)="startLogin()" class="button-header">
        <img *ngIf="!isLoading" alt="logo" class="logo-size" src="assets/google_icon.svg">
        <mat-progress-spinner *ngIf="isLoading" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
        <span>Sign in with Google</span>
        <button [disabled]="isLoading" color="primary" data-type="button-firebase-google-login" mat-icon-button>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </ao-google-firebase-login>
  </div>

  <div *ngIf="authMode !== 'gmail'" class="mat-elevation-z2 sign-in-button">
    <ao-ms-login
            (loginError)="loginError($event)"
            (loginSuccess)="microsoftLoginSuccess($event)"
            [disabled]="isLoading"
            [firebaseApiKey]="firebaseApiKey"
            [firebaseAuthDomain]="firebaseAuthDomain"
            [scopes]="microsoftScope"
            [tenantId]="null"
            [loginHint]="userEmail"
            display-mode="content"
            id="msLogin"
    >
      <div (click)="startLogin()" class="button-header">
        <img *ngIf="!isLoading" alt="Microsoft logo" src="assets/microsoft_icon.svg"/>
        <mat-progress-spinner *ngIf="isLoading" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
        <span>Sign in with Microsoft</span>
        <button [disabled]="isLoading" color="primary" data-type="button-firebase-microsoft-login" mat-icon-button>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </ao-ms-login>
  </div>
</section>
