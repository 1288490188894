import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-report-detail-actions-column',
  templateUrl: './detail-actions-column.component.html',
  styleUrls: ['./detail-actions-column.component.scss']
})
export class DetailActionsColumnComponent {

  @Input() messageExportJob: any;
  @Input() retryEnable = true;
  @Output() retryEvent = new EventEmitter<string>();

  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('ao_open_in_new', sanitizer.bypassSecurityTrustResourceUrl('assets/img/ao_open_in_new.svg'));
    iconRegistry.addSvgIcon('ao_replay', sanitizer.bypassSecurityTrustResourceUrl('assets/img/ao_replay.svg'));
  }

  retry(jobId: string): void {
    this.retryEvent.emit(jobId);
  }

}
