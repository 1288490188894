<section class="main-content">
  <section class="header-section">
    <section class="title-section">
      <h1>
        <span class="arrow-back"
              data-type="button-go-to-rules"
              [matTooltip]="'Back'"
              (click)="gotoRootPath()">
          <mat-icon>arrow_back</mat-icon>
        </span>
        Batch export <span *ngIf="timestamp">{{ timestamp | date: 'medium' }}</span>
      </h1>
    </section>
    <section class="actions-section">
      <section class="filter-section">
        <app-report-filter-export
          [title]="'Filter by rule'"
          [filterValues]="ruleFilterValues"
          [currentValue]="currentRuleIdFilter"
          (filterByEvent)="filterByRule($event)">
        </app-report-filter-export>
        <app-report-filter-export
          [title]="'Filter by status'"
          [filterValues]="statusFilterValues"
          [currentValue]="currentStatus"
          (filterByEvent)="filterByStatus($event)">
        </app-report-filter-export>
      </section>
      <section class="buttons-section">
        <button mat-mini-fab
                color="accent"
                data-type="button-refresh"
                (click)="refreshList()"
                matTooltip="Refresh list">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>
  </section>
  <div class="card-container">
    <mat-card class="no-padding">
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section class="table-section">
      <table mat-table [dataSource]="dataSource" [hidden]="loading$ | async">
        <!-- rule -->
        <ng-container matColumnDef="rule">
          <mat-header-cell class="lg-cell" *matHeaderCellDef>Rule</mat-header-cell>
          <mat-cell class="lg-cell" *matCellDef="let element">
            <span data-type="job-rule"> {{element.ruleName}}</span>
          </mat-cell>
        </ng-container>

        <!-- label -->
        <ng-container matColumnDef="label">
          <mat-header-cell class="lg-cell" *matHeaderCellDef>
            <ng-container *ngIf="isGoogle">Gmail label</ng-container>
            <ng-container *ngIf="!isGoogle">Outlook folders</ng-container>
          </mat-header-cell>
          <mat-cell class="lg-cell" *matCellDef="let element">
            <span data-type="job-label"> {{element.labelName}}</span>
          </mat-cell>
        </ng-container>

        <!-- messageSubject -->
        <ng-container matColumnDef="subject">
          <mat-header-cell class="xxl-cell" *matHeaderCellDef>Subject / File name</mat-header-cell>
          <mat-cell class="xxl-cell" *matCellDef="let element">
            <app-report-calculated-title [messageExportJob]="element"></app-report-calculated-title>
          </mat-cell>
        </ng-container>

        <!-- messageId -->
        <ng-container matColumnDef="messageId">
          <mat-header-cell class="xxl-cell" *matHeaderCellDef>Email ID</mat-header-cell>
          <mat-cell class="xxl-cell" *matCellDef="let element">
            <app-report-email-id-column [emailId]="element.messageId"></app-report-email-id-column>
          </mat-cell>
        </ng-container>

        <!-- status -->
        <ng-container matColumnDef="status">
          <mat-header-cell class="md-cell" *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell class="md-cell" *matCellDef="let element">
            <app-report-status-column
              [statusClass]="element.statusClass"
              [userStatus]="element.userStatus">
            </app-report-status-column>
          </mat-cell>
        </ng-container>

        <!-- export message -->
        <ng-container matColumnDef="exportMessage">
          <mat-header-cell class="xxl-cell" *matHeaderCellDef>
            <span>Details</span>
          </mat-header-cell>
          <mat-cell class="xxl-cell" *matCellDef="let element">
            <app-report-detail-column [messageExportJob]="element" [isRuleExportDetail]="true"></app-report-detail-column>
          </mat-cell>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell class="sm-cell" *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell class="sm-cell" *matCellDef="let element">
            <app-report-detail-actions-column [messageExportJob]="element" (retryEvent)="retry($event)"></app-report-detail-actions-column>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" data-type="job-no-item">No item to display.</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="[pageSize]"
        [hidePageSize]="true"
        [showFirstLastButtons]="false"
        [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)"></mat-paginator>
    </section>
  </mat-card>
  </div>
</section>
