import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Rule} from '../../../../models/rule.model';
import {AuthProvider} from '../../../../constants/auth-provider-types';
import {AuthService} from '../../../../services/auth.service';
import {isTFLibrary, SimpleLibrary} from '../../../../models/simple-library.model';


@Component({
  selector: 'app-rule-viewer',
  templateUrl: './rule-viewer.component.html',
  styleUrls: ['./rule-viewer.component.scss']
})
export class RuleViewerComponent implements OnInit {

  public isGoogleProvider: boolean;
  public authProviderEnum = AuthProvider;
  public showDocumentClass: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Rule,
              private dialogRef: MatDialogRef<RuleViewerComponent>,
              private authService: AuthService,
  ) {
    this.isGoogleProvider = this.authService.isGoogleProvider();
    this.showDocumentClass  = !isTFLibrary(data.library);
  }

  ngOnInit(): void {
  }

  edit(): void{
    this.dialogRef.close(true);
  }
}
