import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ErrorComponent} from './components/error/error.component';
import {AuthContext} from './models/AuthContext.model';
import {Apis} from './constants/apis';
import {environment} from '../environments/environment';
import {Scope} from './constants/scopes';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MicrosoftAuthComponent} from './components/auth/microsoft-auth.component';
import {AuthModule} from './services/interceptors/auth.module';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {MatMenuModule} from '@angular/material/menu';
import {HeaderModule} from './components/header/header.module';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    MicrosoftAuthComponent,
    LoginPageComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    DashboardModule,
    AuthModule,
    HeaderModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: AuthContext,
      useValue: {
        apis: [Apis.CLIENT].join(':'),
        clientId: environment.clientId,
        scope: [Scope.EMAIL, Scope.PROFILE, Scope.GMAIL]
      }
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
