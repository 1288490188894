import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
})
export class SearchInputComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() valueSubject: Subject<string>;
  @Input() clearSubject: Subject<boolean>;
  @Input() placeholder?: string;
  @ViewChild(MatInput) input: MatInput;

  private subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.clearSubject.subscribe(() => {
        this.clear();
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.input.stateChanges.subscribe(() => {
        this.valueSubject.next(this.input.value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clear(): void {
    this.input.value = '';
  }
}
