import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PersonalSettings} from '../../../models/personal-settings.model';
import {PersonalSettingsService} from '../../../services/personal-settings.service';
import {DialogService} from '../../../services/dialog.service';
import {UserService} from '../../../services/user.service';
import {filter} from 'rxjs/operators';
import {AuthProvider} from '../../../constants/auth-provider-types';
import {AuthService} from '../../../services/auth.service';
import {NotificationTypes} from '../../../constants/notification-types';

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss']
})
export class PersonalSettingsComponent implements OnInit, OnDestroy {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public form: FormGroup;

  public personalSettings: PersonalSettings = {
    notificationSettings: {
      bulkNotificationType: NotificationTypes.IN_CASE_OF_ERROR,
      singleExportNotificationType: NotificationTypes.IN_CASE_OF_ERROR
    },
    email: this.userService.user?.email
  };
  public isMicrosoft = this.authService.authProvider === AuthProvider.MICROSOFT;

  private subscriptions: Subscription = new Subscription();

  notificationTypesOptions = [
    { value: NotificationTypes.ALWAYS, label: 'Always' },
    { value: NotificationTypes.IN_CASE_OF_ERROR, label: 'In case of errors' },
    { value: NotificationTypes.NEVER, label: 'Never' }
  ];

  constructor(private personalSettingsService: PersonalSettingsService,
              private authService: AuthService,
              private dialogService: DialogService,
              private userService: UserService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
    const userSubscription = this.userService.userSubject.pipe(
      filter(user => !!user),
    ).subscribe(user => {
      this.personalSettings = {
        notificationSettings: {
          bulkNotificationType: NotificationTypes.IN_CASE_OF_ERROR,
          singleExportNotificationType: NotificationTypes.IN_CASE_OF_ERROR
        },
        email: user.email
      };
      this.initForm();
      this.load();
    });
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.form = this.fb.group({
        bulkNotificationType: new FormControl<NotificationTypes>(
          this.personalSettings.notificationSettings?.bulkNotificationType, {nonNullable: true}
        ),
        singleExportNotificationType: new FormControl<NotificationTypes>(
          this.personalSettings.notificationSettings?.singleExportNotificationType, {nonNullable: true}
        )
      },
      {}
    );
  }

  load(): void {
    this.loadingSubject.next(true);
    this.personalSettingsService.get().subscribe((resp) => {
      this.personalSettings = resp;
      if (!this.personalSettings) {
        this.personalSettings = {
          email: this.userService.user.email
        };
      }

      if (!this.personalSettings.notificationSettings) {
        this.setDefaultNotificationSettingsIfEmpty();
      }

      this.initForm();

      this.loadingSubject.next(false);
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(error.error.error.message);
    });
  }

  save(): void {
    this.loadingSubject.next(true);
    const personalSettings: PersonalSettings = {
      notificationSettings: {
        bulkNotificationType: this.form.getRawValue().bulkNotificationType,
        singleExportNotificationType: this.form.getRawValue().singleExportNotificationType
      },
      email: this.userService.user.email
    };
    this.personalSettingsService.createOrUpdate(personalSettings).subscribe(() => {
      this.loadingSubject.next(false);
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(error.error.error.message);
    });
  }

  delete(): void {
    this.loadingSubject.next(true);
    this.personalSettingsService.delete().subscribe(() => {
      this.loadingSubject.next(false);
      this.form = this.fb.group({
        bulkNotificationType: [NotificationTypes.IN_CASE_OF_ERROR],
        singleExportNotificationType: [NotificationTypes.IN_CASE_OF_ERROR]
      });
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(error.error.error.message);
    });
  }

  formDisabled(): boolean {
    return !this.form.valid;
  }

  private setDefaultNotificationSettingsIfEmpty(): void {
    if (!this.personalSettings.notificationSettings) {
      this.personalSettings.notificationSettings = {
        bulkNotificationType: NotificationTypes.IN_CASE_OF_ERROR,
        singleExportNotificationType: NotificationTypes.IN_CASE_OF_ERROR
      };
    }
  }
}
