import {Component, OnInit} from '@angular/core';
import {filter} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {DialogService} from '../../services/dialog.service';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';
import {AuthProvider} from '../../constants/auth-provider-types';
import {LOGIN_ERROR_LABEL} from '../../constants/auth-error';
import {AuthMode, AuthStorage} from '../../models/auth-storage.model';
import {LocalStorageUtils} from '../../services/local-storage-utils';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  authMode: AuthMode.GMAIL | AuthMode.OUTLOOK | AuthMode.BOTH = AuthMode.BOTH; // TODO maybe change authMode to be an object with a type to handle those values
  domain: string;
  userEmail: string;
  firebaseApiKey = environment.firebaseApiKey;
  firebaseAuthDomain = environment.firebaseAuthDomain;
  isLoading = false;
  emailAddressChanged = false;

  scopes = AuthService.getScopes();
  microsoftScope = AuthService.getScopes(false);

  private readonly defaultProfilePicture = './assets/img/google-default-profile-picture.jpg';

  constructor(private readonly authService: AuthService,
              private readonly dialogService: DialogService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) {
  }

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    this.authMode = params.origin || AuthMode.BOTH;
    this.domain = params.domain;
    this.userEmail = params.user ? params.user : undefined;
    this.isLoading = true;
    this.authService.initiatedAuth.pipe(filter(isLoaded => isLoaded)).subscribe(() => {
      this.isLoading = false;
    });
    this.emailAddressChanged = !!this.authService.userEmailUpdateInProgressValue;
  }

  startLogin(): void {
    setTimeout(() => {
      this.isLoading = true;
    });
  }

  googleLoginSuccess(
    event: {
      detail: {
        email: string,
        name: string,
        picture: string,
        firebaseAccessToken?: string,
        firebaseRefreshToken?: string
      }
    },
  ): void {
    this.loginSuccess(event, AuthProvider.GOOGLE_FIREBASE);
  }

  microsoftLoginSuccess(event: any): void {
    this.loginSuccess(event, AuthProvider.MICROSOFT);
  }

  loginError(event: any): void {
    console.error(event);
    this.isLoading = false;
    this.dialogService.error(`Error: ${LOGIN_ERROR_LABEL}`);
    if (this.authMode === AuthMode.GMAIL) {
      this.domain = '';
      this.authMode = AuthMode.BOTH;
    }
  }

  private loginSuccess(event: any, authProvider: AuthProvider): void {
    let storedInfo: AuthStorage = {
      type: authProvider,
      email: event.detail.email,
      name: event.detail.name,
      picture: authProvider === AuthProvider.MICROSOFT ? this.defaultProfilePicture : event.detail.picture,
      accessToken: event.detail.firebaseAccessToken
    };
    storedInfo = {...storedInfo, refreshToken: event.detail.firebaseRefreshToken};

    if (!!this.domain) {
      storedInfo = {...storedInfo, domain: this.domain};
    }

    LocalStorageUtils.setAuthStorage(storedInfo);
    this.authService.setAuthType(authProvider);
    this.authService.setToken(storedInfo.accessToken);
    this.authService.setProfile({
      name: storedInfo.name,
      email: storedInfo.email,
      picture: storedInfo.picture
    });

    // Redirect to the page the user was trying to access before logging in
    this.router.navigateByUrl(this.authService.redirectUrl);
    // keep the redirect url in case the user has to be redirected after updating the email after marriage...
    this.authService.firebaseLastRedirectUrl = this.authService.redirectUrl as UrlTree;
    // reset the redirect url to the default one
    this.authService.redirectUrl = '/';
  }
}
